import React from 'react'
import Navbar from '../inc/Navbar'

import ScrollToTop from "react-scroll-to-top";
import Namdiamayapur from "../images/MAYAPUR-NADIA.jpg";
import berhamporehazarduari from "../images/Hazarduari-murshidabad.jpg";
import Kolkatacityofjoy from "../images/kolkata-city of joy.jpg";
import North24porganas from "../images/north24parganas.jpg";

import Landlist from './Landlist';
import Slider from '../inc/Slider';
import "./Home.css";
import landsell from "../images/land sell.jpg";
import About from './About';
import NadiaV from './NadiaV';


function Home() {
    return (
        <div>


            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Slider />

            <ScrollToTop smooth top="100" color="Gray"/>
            <br />
            <div className="album py-1">
                <div className="container">
                    <h2><b style={{ color: 'grey' }}>Best Land in</b> Nadia </h2>
                </div>
            </div>
            <Landlist />
            <NadiaV/>
            <br />
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mt-3">
                          
                              
                                <div className="card-body">
                                    <h2 className="card-title  mb-3">Sell or Rent your property for free on <b>The Land Bucks</b>!</h2>
                                    <p style={{color:"grey"}} className="card-text justufytext">Looking to sell or rent your property? Check out The Land Bucks! You can list your property for free, reaching a wide audience without any hidden fees. Whether you're looking to sell your home or find a great tenant, The Land Bucks offers a user-friendly platform to make the process easy and efficient. Take advantage of their tools and resources to showcase your property and connect with potential buyers or renters today!</p>
                                    <a href="ListProperty" className="nadialistyourland">List Your Property <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></a>
                                </div>
                            
                        </div>
                        <div className="col-md-7 mt-3">
                           
                                <img src={landsell} className="card-img-top" alt="landsellnadia"/>
                               
                        </div>
                    </div>
                </div>
            </div>


<br/>
<br/>
<hr/>

<About/>
<br/>
<br/>


            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Namdiamayapur} className="card-img-top" alt="Namdiamayapur" />
                                        <div className="card-body">
                                            <center>
                                                <p className="card-text">Nadia</p>
                                            </center>



                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={berhamporehazarduari} className="card-img-top" alt="berhamporehazarduari" />
                                        <div className="card-body">
                                            <center>
                                                <p className="card-text">Murshidabad</p>
                                            </center>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Kolkatacityofjoy} className="card-img-top" alt="Kolkatacityofjoy" />
                                        <div className="card-body">
                                            <center>
                                                <p className="card-text">Kolkata</p>
                                            </center>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={North24porganas} className="card-img-top" alt="North24porganas" />
                                        <div className="card-body">

                                            <center>
                                                <p className="card-text">North 24 Porganas</p>
                                            </center>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />

        </div>
    )
}

export default Home