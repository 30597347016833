import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
function Customercare() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Gray" />
            <div className='krishnagrland'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a style={{ color: "white" }} href="/">Home</a></li>
                                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Customer Care</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">
                <div className="container ">

                    <h2 className='mb-3'><b>Customer Care at The Land Bucks</b></h2>
                    <h6 className='mb-4'>At The Land Bucks, our customers are at the heart of everything we do. We understand that purchasing land is a significant decision, and our dedicated Customer Care team is here to support you every step of the way. Whether you're exploring our available properties, have questions about financing, or need assistance with closing the deal, we're committed to providing prompt, friendly, and expert service.</h6>
                    <h3>How We Can Help:</h3>
                    <h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> Property Inquiries:</b> Get detailed information about any of our available listings.
                    </h6>
                    <h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> Guidance Through the Process:</b> Our team will walk you through the entire land-buying process, from initial inquiries to final paperwork.
                    </h6>
                    <h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> Financing Options: </b> We’ll help you explore flexible payment plans that suit your financial situation.
                    </h6>
                    <h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg> Post-Purchase Support: </b> Have questions or need help after your purchase? We’re still here for you.
                    </h6>
                    <br />
                    <h3>Contact Us:                </h3>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <center>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "maroon" }} width="30" height="30" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708" />
                                        </svg></h5>
                                        <p style={{ color: "grey" }} className="card-text">Call us on +91 8371830744</p>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="col-md-4 mt-3">
                            <center>
                                <div className="card" >
                                    <div className="card-body">
                                        <h5 className="card-title"><svg xmlns="http://www.w3.org/2000/svg" style={{color:"darkBlue"}} width="30" height="30" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg></h5>
                                        <p style={{ color: "grey" }} className="card-text">Email:  update</p>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="col-md-4 mt-3">
                            <center>
                                <div className="card" >
                                    <div className="card-body">
                                        <h5 className="card-title"><svg xmlns="http://www.w3.org/2000/svg" style={{color:"darkgoldenrod"}} width="30" height="30" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
  <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
  <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
</svg></h5>
                                        <p style={{ color: "grey" }} className="card-text">Everyday 10:00AM to 10:00PM</p>
                                    </div>
                                </div>
                            </center>
                            <br/>
                        </div>
                    </div>
                    <h6>At <b>The Land Bucks</b>, we're committed to ensuring that your land-buying experience is smooth, stress-free, and rewarding. If you have any questions or concerns, don’t hesitate to reach out. We're here to help!</h6>

                </div>
            </div>

            <br />
            <br />

        </div>
    )
}

export default Customercare