import React from 'react'
import Navbar from '../inc/Navbar'
import Landlist from './Landlist'
import ScrollToTop from "react-scroll-to-top";
import NadiaV from './NadiaV';
function Nadialand() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Gray"/>
            <div className='krishnagrland'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a style={{color:"white"}} href="/">Home</a></li>
                                <li style={{color:"white"}} className="breadcrumb-item active" aria-current="page">Nadia Land</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Landlist />
            <NadiaV/>

        </div>
    )
}

export default Nadialand