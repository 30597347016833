import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'







function Landlist() {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };


    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [homesideland, setHomesideland] = useState([])
    const navigate = useNavigate()

    const homesidelandCollectionRef = collection(db, "HOME-LAND LIST");
    useEffect(() => {

        const getHomesideland = async () => {
            const data = await getDocs(homesidelandCollectionRef);
            setHomesideland(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getHomesideland()
    }, [])
    return (

        <>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {homesideland.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((thelandbucks) => {
                            return (
                                <div className="col-md-4 mt-3" >
                                    <div className="card mb-4 border-White box shadow">
                                        <div className="btn-grad44  mb-0"> <h6 className='blink mt-2'>KRISHNAGAR Gobrapota Land Sale</h6> </div>
                                        <img className="card-img-top" src={thelandbucks.img} alt="news" />
                                        <div className="card-body">



                                            <h4 className='mb-0' style={{ color: "green" }}><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg> {thelandbucks.title}</h4>
                                            <p style={{ color: "maroon" }} className="card-text mb-1"> <small>₹ {thelandbucks.price}</small></p>
                                            <p className="card-text mb-2"> <small>{thelandbucks.des}</small></p>
                                            <p className="card-text mb-2"> <small>{thelandbucks.katha}</small></p>


                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: +91 8371830744'>
                                                    <div className="btn-grad31" type="submit">Call Now</div>
                                                </a>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Landlist;



